<template>
  <div>
    <v-card>
      <v-card-text>
        <v-alert type="info" dense text>
          <span>
            Tu calificación en este foro es de
            <v-chip label class="mx-1" color="secondary"
              ><strong>{{ calificacion }}</strong></v-chip
            >
            puntos
          </span>
        </v-alert>

        <comentarios-foro
          :idActividad="actividad._id"
          :descripcion="actividad.descripcion"
          :entregaActividad="{
            ...actividad.disponible,
            entregaExt: actividad.entregaExt,
          }"
          :idCurso="actividad.cursoCampus"
          :instrumento="
            actividad.instrumento ? actividad.instrumento._id : null
          "
          :tipoInstrumento="actividad.tipoInstrumento"
          :puedeComentar="actividad.entrega == 1 ? true : esAdmin"
          :equipos="equipos"
          :cursoArchivado="cursoArchivado"
        ></comentarios-foro>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    calificaciones: {
      type: Array,
      default: () => [],
    },

    actividad: {
      type: Object,
    },

    esAdmin: {
      type: Boolean,
      default: false,
    },

    equipos: {
      type: Array,
      default: () => [],
    },
    cursoArchivado: { type: Boolean, default: false },
  },

  components: {
    "comentarios-foro": () => import("../../../comentarios/Comentarios.vue"),
  },

  computed: {
    calificacion() {
      if (!this.calificaciones.length) return 0;
      const calificacion = this.calificaciones[0];
      const result = calificacion.puntosExtra
        ? calificacion.puntosExtra + calificacion.calificacion
        : calificacion.calificacion;

      return result >= 100 ? 100 : result <= 0 ? 0 : result;
    },
  },
};
</script>
